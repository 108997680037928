<template>
  <div class="st-participant-segments__form">
    <a
      href="#"
      class="st-participant__back-icon st-participant-segments__icon"
      @click="$goBack"
    >
      <icon-wrapper
        class="st-hyperlink__back-icon"
        icon-name="chevron"
      />
    </a>
    <div class="st-participant-segments__title">
      {{ title }}
    </div>
    <div class="st-participant-segments__text">
      {{ text }}
    </div>
    <div class="st-participant-segments__inputs">
      <template v-if="activePageIndex === 0 && !survey.anonymous">
        <st-input
          v-model="firstName"
          class="st-participant-segments__input"
          label="First Name"
          placeholder="First Name"
          :show-label="true"
          :variant="['fullBorder', 'largeFieldLabel', 'persistLabelAbove']"
          tab-index="1"
        />
        <st-input
          v-model="lastName"
          class="st-participant-segments__input"
          label="Last Name"
          placeholder="Last Name"
          :show-label="true"
          :variant="['fullBorder', 'largeFieldLabel', 'persistLabelAbove']"
          tab-index="2"
        />
        <template v-for="(segment, segmentIndex) in pagesArray[activePageIndex]">
          <st-dropdown-multiselect
            v-if="segment.multipleSelect"
            :key="'segment-' + segment.id"
            class="st-participant-segments__dropdown"
            :caption="segment.name"
            :display-caption="true"
            :full-width="true"
            :options="segment.options"
            :option-caption-fn="(option) => option.name"
            :select-multiple="true"
            :initial-active-options-array="initialSelected(segment)"
            :variant="['fullBorder', 'persistLabelAbove']"
            :tab-index="segmentIndex+3"
            @updateMultiple="(val) => updateMultiple(val,segment)"
          />
          <st-dropdown-multiselect
            v-else
            :key="'segment-' + segment.id"
            class="st-participant-segments__dropdown"
            :caption="segment.name"
            :display-caption="true"
            :full-width="true"
            :initial-active-option="selectedOptionName(segment)"
            :options="optionNames(segment.options)"
            :variant="['fullBorder', 'persistLabelAbove']"
            :tab-index="segmentIndex+3"
            @updateOption="(val) => updateSingleSelect(val, segment)"
          />
        </template>
      </template>
      <template v-else>
        <template v-for="(segment, segmentIndex) in pagesArray[activePageIndex]">
          <st-dropdown-multiselect
            v-if="segment.multipleSelect"
            :key="'segment-' + segment.id"
            class="st-participant-segments__dropdown"
            :caption="segment.name"
            :display-caption="true"
            :full-width="true"
            label-class="st-participant-segments__dropdown-label"
            :options="segment.options"
            :option-caption-fn="(option) => option.name"
            :select-multiple="true"
            :initial-active-options-array="initialSelected(segment)"
            :variant="['fullBorder', 'persistLabelAbove']"
            :tab-index="segmentIndex+1"
            @updateMultiple="(val) => updateMultiple(val,segment)"
          />
          <st-dropdown-multiselect
            v-else
            :key="'segment-' + segment.id"
            class="st-participant-segments__dropdown"
            :caption="segment.name"
            :display-caption="true"
            :full-width="true"
            :initial-active-option="selectedOptionName(segment)"
            :options="optionNames(segment.options)"
            :variant="['fullBorder', 'persistLabelAbove']"
            :tab-index="segmentIndex+1"
            @updateOption="(val) => updateSingleSelect(val, segment)"
          />
        </template>
      </template>
    </div>
    <div class="st-participant-segments__footer-group">
      <div class="st-participant-segments__continue-row">
        <div class="st-participant-segments__pages">
          <div class="st-participant-segments__pages-text">
            {{ activePageIndex + 1 }}/{{ pagesArray.length }}
          </div>
          <div
            v-if="activePageIndex>0"
            class="st-participant-segments__page-icon"
            @mouseenter="previousHover = true"
            @mouseleave="previousHover = false"
            @click="activePageIndex -= 1"
          >
            <icon-wrapper
              icon-name="previous"
              :actionable="false"
            />
          </div>
          <div
            v-if="pagesArray.length>1"
            :class="{
              'st-participant-segments__page-icon': true,
              'st-participant-segments__disabled-icon': !isActivePageComplete || activePageIndex === pagesArray.length - 1
            }"
            @mouseenter="nextHover = true"
            @mouseleave="nextHover = false"
            @click="tryNext"
          >
            <icon-wrapper
              icon-name="next"
              :actionable="false"
            />
          </div>
        </div>
        <st-button
          class="st-participant-segments__continue"
          caption="Continue"
          icon-name="arrow"
          :disabled="!isActivePageComplete"
          :underline="false"
          :variant="['secondary']"
          @click="handleContinue"
        />
      </div>
      <div class="st-participant-segments__footer">
        <div class="st-participant-segments__footer-text">
          Powered by
        </div>
        <icon-wrapper
          class="st-participant-segments__footer-icon"
          icon-name="spacetrics"
          :actionable="false"
        />
        <div class="st-participant-segments__footer-text">
          spacetrics
        </div>
      </div>
    </div>
    <UnsupportedBrowser v-if="survey.hasVoiceRecording" />
  </div>
</template>

<script>
import UnsupportedBrowser from '../../participant/unsupported-browser'
import { sendNameEmail } from '../../../utilities/fullstory'
import { isDesktop } from '../../../mixins'
import {
  IconWrapper,
  StButton,
  StDropdownMultiselect,
  StInput
} from '../../common'

const SLOTS_PER_PAGE = 4

export default {
  components: {
    // 'unsupported-browser': UnsupportedBrowser,
    IconWrapper,
    StButton,
    StInput,
    StDropdownMultiselect,
    UnsupportedBrowser
  },
  mixins: [isDesktop],
  props: {
    survey: {
      type: Object,
      required: true
    },
    initialParticipant: {
      type: Object,
      required: false,
      default: () => ({})
    },
    isPreview: {
      type: Boolean,
      required: false,
      default: false
    },
    hasVoiceQuestion: {
      type: Boolean,
      required: false,
      default: true
    },
    spacetricsLogo: {
      type: String,
      required: false,
      default: ''
    },
    isJoinSurvey: {
      type: Boolean,
      required: false,
      default: false
    }
  },
  data: function () {
    return {
      segments: this.survey.segments.map(segment =>
        this.initialSegmentValue(segment)
      ),
      firstName: this.initialParticipant.firstName,
      lastName: this.initialParticipant.lastName,
      activePageIndex: 0
    }
  },
  computed: {
    pagesArray () {
      // pagesArray is an array where each entry represents a page
      // each page will contain an array of references to segments
      let pagesArray = [[]]
      let currentPageSlot = 2 // account for first and last name when survey is not anonymous
      if (this.survey.anonymous) { currentPageSlot = 0 }
      let currentPageIndex = 0
      this.evaluateSegmentCompleteness()
      this.segments.forEach(segment => {
        currentPageSlot++
        if (currentPageSlot > SLOTS_PER_PAGE) {
          // go to next page
          currentPageIndex += 1
          pagesArray.push([segment])
          currentPageSlot = 1
        } else {
          // add to current page
          pagesArray[currentPageIndex].push(segment)
        }
      })
      return pagesArray
    },
    title () {
      return this.activePageIndex === 0 ? 'Great!' : '...'
    },
    text () {
      return this.activePageIndex === 0 ? 'We just need to know a little more about you.' : 'Just a little more'
    },
    activeSegmentsComplete () {
      let segments = this.pagesArray[this.activePageIndex]
      let unanswered = 0
      segments.forEach(segment => {
        if (!segment.completed) { unanswered++ }
      })
      return unanswered === 0
    },
    isActivePageComplete () {
      if (!this.survey.anonymous && this.activePageIndex === 0) {
        if (!this.firstName) { return false }
        if (!this.lastName) { return false }
      }
      return this.activeSegmentsComplete
    },
    isLastPageActive () {
      if (this.activePageIndex + 1 === this.pagesArray.length) { return true }
      return false
    },
    showContinueButton () {
      if (this.isDesktop) { return true }
      if (!this.isDesktop && this.isActivePageComplete) { return true }
      return false
    }
  },
  mounted () {
    this.$store.commit('hideSidebar', true)

    if (this.survey.anonymous && this.segments.length === 0) {
      if (this.isPreview) {
        window.location.href = '/questions/' + this.survey.firstQuestionId + '/preview'
      } else {
        window.location.href = '/questions/' + this.survey.firstQuestionId
      }
    }

    if (this.isPreview) { return }
    sendNameEmail(
      this.initialParticipant.fullName,
      this.initialParticipant.email
    )
  },
  methods: {
    evaluateSegmentCompleteness () {
      this.segments.forEach(segment => {
        if (segment.multipleSelect) {
          segment.completed = segment.checkedOptions.length > 0
        } else {
          segment.completed = Boolean(segment.selectedOption)
        }
        return segment
      })
    },
    initialSegmentValue (segment) {
      let defaultSegment = {
        completed: false,
        checkedOptions: [],
        selectedOption: null
      }
      if (this.initialParticipant.segmentResponses.hasOwnProperty(segment.id)) {
        let propName = segment.multipleSelect
          ? 'checkedOptions'
          : 'selectedOption'
        defaultSegment[propName] = this.initialParticipant.segmentResponses[segment.id]
      }
      return Object.assign(defaultSegment, segment)
    },
    handleContinue () {
      if (this.isPreview) { return this.progressPageForPreview() }

      if (this.activePageIndex === 0 && !this.survey.anonymous) {
        this.submitInfo() // submit the first and last name
      }

      if (this.isLastPageActive) {
        this.progressLastPage()
      } else {
        this.activePageIndex += 1
      }
    },
    progressPageForPreview () {
      if (this.segments.length === 0) {
        window.location.href = '/questions/' + this.survey.firstQuestionId + '/preview'
      }

      if (this.isLastPageActive) {
        window.location.href = '/questions/' + this.survey.firstQuestionId + '/preview'
      } else {
        this.activePageIndex += 1
      }
    },
    progressLastPage () {
      this.submitSegmentResponses()
    },
    submitInfo () {
      let url = `/surveys/${this.survey.id}/response?participant_token=${this.initialParticipant.token}`
      this.$axios
        .request({
          url: url,
          method: 'post',
          data: {
            participant: {
              first_name: this.firstName,
              last_name: this.lastName
            }
          }
        })
        .then(res => {
          if (this.segments.length === 0) {
            window.location.href = `/questions/${this.survey.firstQuestionId}?participant_token=${this.initialParticipant.token}`;
          }
          if (this.pagesArray.length === 1 && this.segments.length !== 0) {
            // submit answers if there is only one page of segments
            this.submitSegmentResponses()
          }
        })
        .catch(err => {
          console.log(err.response.data)
        })
    },
    submitSegmentResponses () {
      let url = `/surveys/${this.survey.id}/segments/submit`
      this.$axios.request({
        url: url,
        method: 'post',
        data: { segments: this.formattedSegmentResponses() }
      })
        .then((res) => {
          console.log('submitSegmentResponses', res)
          if (res.data.first_unanswered_question_id) {
            window.location.href = '/questions/' + res.data.first_unanswered_question_id
          } else {
            window.location.href = `/surveys/${this.survey.id}/complete?returning=true`
          }
        })
        .catch(err => {
          console.log(err.response.data)
        })
    },
    formattedSegmentResponses () {
      return this.segments.reduce((segmentsHash, segment) => {
        segmentsHash[segment.id] = segment.multipleSelect
          ? segment.checkedOptions
          : [segment.selectedOption]
        return segmentsHash
      }, {})
    },
    optionNames (options) {
      return options.map(option => option.name)
    },
    selectedOptionName (segment) {
      if (segment.selectedOption) {
        let idx = segment.options.map(option => option.id).indexOf(Number(segment.selectedOption))
        return segment.options[idx].name
      } else {
        return ''
      }
    },
    updateSingleSelect (newOption, segment) {
      let idx = segment.options.map(option => option.name).indexOf(newOption)
      segment.selectedOption = segment.options[idx].id
      segment.completed = true
    },
    initialSelected (segment) {
      let selectedOptions = []
      if (segment.checkedOptions) {
        segment.checkedOptions.forEach(optionId => {
          let idx = segment.options.map(option => option.id).indexOf(Number(optionId))
          selectedOptions.push(segment.options[idx])
        })
        return selectedOptions
      } else {
        return []
      }
    },
    updateMultiple (newOptions, segment) {
      segment.checkedOptions = newOptions.map(option => option.id)
      segment.completed = newOptions.length > 0
    },
    tryNext () {
      if (this.isActivePageComplete && this.activePageIndex !== this.pagesArray.length - 1) {
        this.activePageIndex += 1
      }
    }
  }
}
</script>
